'use strict'

import _ from 'lodash';
import state from '../util/state.js';

const HeaderNav = class {
  constructor() {
    document.ontouchmove = function(e){ return true; }

    if(state.dev === 'sp') {
      this.tglNav();
    }

    window.addEventListener('resize', _.throttle(() => {
      if(state.dev === 'sp') {
        this.tglNav();
      }
    }, 500));
  }

  tglNav() {
    const btn = document.querySelector('.js-header-nav'),
          nav = document.querySelector('.nav'),
          body = document.querySelector('body');

    btn.onclick = function(e) {
      e.preventDefault();
      const btn = this;

      if(!state.tglOpen) {
        body.classList.add('fixed');
        document.ontouchmove = function(e){ e.preventDefault(); }
        state.fixed = true;

        _.forEach({ btn, nav }, function(n, key) {
          n.classList.add('current');
        });
      } else {
        body.classList.remove('fixed');
        document.ontouchmove = function(e){ return true; }
        state.fixed = false;

        _.forEach({ btn, nav }, function(n, key) {
          n.classList.remove('current');
        });
      }
      state.tglOpen = !state.tglOpen;
    }
  }
};
export default HeaderNav;
