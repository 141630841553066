'use strict'

import _ from 'lodash';
import state from '../util/state';

const Header = class {
  constructor() {
    this.navState();

    window.addEventListener('scroll', _.throttle(this.navScroll.bind(this), 50));
      this.navScroll();
  }

  navState() {
    const urlPath = location.pathname;

    if(urlPath === '/') {
      document.querySelector('.gnav_top').classList.add('current');
    } else if(urlPath.indexOf('stay') > -1) {
      document.querySelector('.gnav_stay').classList.add('current');
    } else if(urlPath.indexOf('eat-drink') > -1) {
      document.querySelector('.gnav_eat-drink').classList.add('current');
    } else if(urlPath.indexOf('facilities') > -1) {
      document.querySelector('.gnav_facilities').classList.add('current');
    } else if(urlPath.indexOf('access') > -1) {
      document.querySelector('.gnav_access').classList.add('current');
    }
  }

  navScroll() {
    const nav = document.querySelector('header.global '),
          pos = document.querySelector('footer.global').getBoundingClientRect().top;
    if (window.innerHeight >= pos) {
      nav.classList.add('disabled');
    } else {
      nav.classList.remove('disabled');
    }
  }
};
export default Header;
