'use strict'

import $ from 'jquery';
import _ from 'lodash';
// import Swiper from 'swiper';
import Swiper from '../../../node_modules/swiper/dist/js/swiper.js';
import state from '../util/state';

const Tab = class {
  constructor(el) {

    $('.js-tab-nav').on('click', function() {
      const target = $(this).attr('rel');

      $('.js-tab-nav, .js-tab-contents').removeClass('active');
      $(this).addClass('active');
      // $('.js-tab-contents').hide();
      $('#' + target).addClass('active');
    });

    if(state.dev === 'sp') {
      if(document.querySelector('.js-sp_swipe')) {
        this.spTab();
      }
    }
  }
  spTab() {
    console.log('spTab');
    const TopSwiper = new Swiper ('.js-sp_swipe', {
      slidesPerView: 'auto',
      spaceBetween: 0,
      freeMode: true,
      freeModeMomentumBounce: false,
      iOSEdgeSwipeDetection: true,
      slideToClickedSlide: true
    });
  }
};
export default Tab;
