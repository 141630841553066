'use strict'

import _ from 'lodash';
// import Swiper from 'swiper';
import Swiper from '../../../node_modules/swiper/dist/js/swiper.js';
import state from '../util/state';

const TopSwiper = class {
  constructor(el) {
    this.tgt = {
      swipers: document.querySelectorAll(el)
    }
    this.init();
  }
  init() {

    const per = (state.dev === 'pc') ? 1 : 1.1;
    const spd = (state.dev === 'pc') ? 1600 : 1000;
    const spc = (state.dev === 'pc') ? 0 : 5;

    _.forEach(this.tgt.swipers, function(n, key) {
      const TopSwiper = new Swiper (n, {
        effect: 'slide',
        speed: spd,
        slidesPerView: per,
        spaceBetween: spc,
        loop: true,
        // centeredSlides: true,
        autoplay: {
          delay: 4500
        },
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev',
        // },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        // on: {
        //   slideChange: function() {
        //     // console.log('change');
        //   },
        // }
      });
    });
  }
};
export default TopSwiper;
