'use strict'

import $ from 'jquery';
import _ from 'lodash';
import state from '../util/state';

const Top = class {
  constructor() {
    $('.kv .catch').addClass('active');

    setTimeout(function() {
      $('.img01, .txt01').addClass('inview');
    }, 1200);
  }
};
export default Top;
