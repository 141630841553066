'use strict'

// import $ from 'jquery';
import state from '../util/state';

const Gmap = class {
  constructor(el) {

    // Base
    const latlng = new google.maps.LatLng(35.25104523,139.04768372),
          devZoom = (state.dev === 'sp') ? 16 : 16,
          myOptions = {
            zoom: devZoom,
            center: latlng,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControl: false,
            fullscreenControl: true,
            streetViewControl: false,
            zoomControl: true
          };

    var map;
    if(document.getElementById('amap')) {
      map = new google.maps.Map(document.getElementById('amap'), myOptions);
    } else if(document.getElementById('fmap')) {
      map = new google.maps.Map(document.getElementById('fmap'), myOptions);
    }

    // Add Style
    const styleOptions1 = [
      {
        'stylers':[{
          'saturation': -100
        }]
      }
    ];
    const styleOptions = [
      {
        'featureType': 'all',
        'elementType': 'all',
        'stylers': [
          {
            'hue': '#ffbb00'
          }
        ]
      },
      {
        'featureType': 'all',
        'elementType': 'geometry.fill',
        'stylers': [
          {
            'hue': '#ffbb00'
          }
        ]
      },
      {
        'featureType': 'all',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'hue': '#ffbb00'
          }
        ]
      }
    ];
    const styledMapOptions = { name: 'BETSUDAI TOKYO' },
          grayType = new google.maps.StyledMapType(styleOptions, styledMapOptions);
    map.mapTypes.set('sample', grayType);
    map.setMapTypeId('sample');

    // Add Marker
    const icon = new google.maps.MarkerImage('/assets/img/common/ico_pin.svg',
      new google.maps.Size(50,58), // icon size
      new google.maps.Point(0,0) //icon position
    );
    const markerOptions = {
      position: latlng,
      map: map,
      icon: icon,
      title: 'BETSUDAI TOKYO'
    };
    const marker = new google.maps.Marker(markerOptions);
    
  }
};
export default Gmap;
