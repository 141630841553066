import './polyfill'

import Header from './modules/Header';
import HeaderNav from './modules/HeaderNav';
import Gmap from './modules/Gmap';
import Scroll from './modules/Scroll';
import Inview from './modules/Inview';
import Link from './modules/Link';
import Swiper from './modules/Swiper';
import Tab from './modules/Tab';
import Top from './modules/Top';

import state from './util/state';

state.dev = (window.innerWidth > 768) ? 'pc' : 'sp';

window.addEventListener('DOMContentLoaded', function() {
  new Header();
  new HeaderNav();
  new Gmap();
  new Link('.js-link');

  if(document.querySelector('.js-swiper')) {
    new Swiper('.js-swiper');
  }
  if(document.querySelector('.js-tab')) {
    new Tab('.js-tab');
  }
  if(document.querySelector('body.index')) {
    new Top();
  }

  new Inview();

  document.querySelector('#wrap').classList.add('ready');
});

window.onload = function(){
  // Load functions
  if(state.dev === 'pc') {
    new Scroll();
  }
};

window.addEventListener('resize', _.throttle(function() {
  state.dev = (window.innerWidth > 768) ? 'pc' : 'sp';
  if(state.dev === 'pc') {
    // new Scroll();
  }
}, 500));
